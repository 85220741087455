<template>
  <div class="interview">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="468px"
    >
      <!--profile-pop start-->
      <div class="profile-pop">
        <div class="profile-content">
          <!--profile-box start-->
          <div class="profile-box">
            <div class="box-txt">
              <div class="t-flex m-b-20">
                <span class="name m-r-15">{{ userInfo.name }}</span>
                <span class="name-t">{{ getPosition(userInfo.position) }}</span>
              </div>
              <div class="c-flex">
                <img class="icon" src="../assets/image/personage/icon32.png" />
                <span>{{ userInfo.mobile }}</span>
              </div>
              <div class="c-flex">
                <img class="icon" src="../assets/image/personage/icon33.png" />
                <span>{{ userInfo.dian_name }}</span>
              </div>
              <div class="c-flex">
                <img class="icon" src="../assets/image/personage/time.png" />
                <span>{{ userInfo.createtime }}</span>
              </div>
            </div>
            <!-- <img  src="../assets/image/personage/rule1.jpg" /> -->
            <img class="box-img" :src="userInfo.image" alt="" />
          </div>
          <!--profile-box end-->
          <!--profile-gray start-->
          <div class="profile-gray">
            
            <img class="wx-img" :src="userInfo.qr_code" />
          </div>
          <!--profile-gray end-->
          <!--profile-foot start-->
          <div class="profile-foot">
            <el-button class="profile-btn" @click="download">下载图片</el-button>
            <!-- <el-button class="profile-btn blue">分享名片</el-button> -->
          </div>
          <!--profile-foot end-->
        </div>
        <div class="profile-bot">
          <i class="el-icon-circle-close" @click="close"></i>
        </div>
      </div>
      <!--profile-pop end-->
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
   computed: {
    ...mapGetters(["userInfo", "token", "city"]),
  },
  data() {
    return {
      dialogVisible: false,
      content: "", //
    };
  },
  methods: {
    downloadImg(url, name) {
    var x=new XMLHttpRequest();
    var resourceUrl = url;
    x.open("GET", resourceUrl, true);
    x.responseType = 'blob';
    x.onload=function(){
        var url = window.URL.createObjectURL(x.response)
        var a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
    }
    x.send();
},
    download () {
      // location.href = this.userInfo.image
      this.downloadImg(this.userInfo.qr_code,'下载')
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.item-flex-img {
  display: flex;
  flex-wrap: wrap;
  .img {
    width: 120px;
    height: 120px;
    border-radius: 4px;
    margin: 0 5px 5px 5px;
  }
}
/deep/ .el-dialog {
  background: none;
  box-shadow: none;
}
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
.form {
  display: flex;
  padding: 30px 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  /deep/ .el-input {
    width: 320px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
.profile-pop {
  .profile-content {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    .profile-box {
      border-radius: 12px;
      padding: 30px;
      background: url("../assets/image/personage/p2.png") no-repeat center;
      background-size: cover;
      color: #fff;
      display: flex;
      justify-content: space-between;
      .box-img {
        width: 88px;
        height: 88px;
        border-radius: 100%;
      }
      .box-txt {
        .t-flex {
          display: flex;
          align-items: center;
        }
        .name {
          font-size: 24px;
        }
        .name-t {
          background: rgba(91, 164, 238, 0.4);
          border-radius: 4px;
          line-height: 28px;
          padding: 0px 15px;
          font-size: 14px;
        }
        .c-flex {
          display: flex;
          align-items: center;
          font-size: 16px;
          margin: 10px 0px;
          .icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }
    }
    .profile-gray {
      background: #f5f5f5;
      padding: 30px;
      margin: 20px 0px;
      .wx-img {
        width: 230px;
        height: 230px;
        display: block;
        border-radius: 12px;
        margin: 0 auto;
      }
    }
    .profile-foot {
      display: flex;
      align-items: center;
      justify-content: center;
      .profile-btn {
        width: 180px;
        height: 56px;
        border-radius: 28px;
        border: 1px solid #3273f6;
        color: #3273f6;
        font-size: 18px;
        padding: 0px;
        background: #fff;
      }
      .profile-btn.blue {
        background: #3273f6;
        color: #fff;
      }
    }
  }
  .profile-bot {
    display: flex;
    justify-content: center;
    padding: 20px;
    color: #fff;
    font-size: 30px;
    font-weight: lighter;
    cursor: pointer;
  }
}
</style>
